import React, { useEffect, useState } from "react";
import styles from "./QrCode.module.scss";
import AppStore from "../../assets/img/App-Store.svg";
import GooglePlay from "../../assets/img/Google-Play.svg";
import Limbic from "../../assets/img/limbic.svg";
import Logo from "../../assets/img/logo.svg";
import Motion from "../../components/Motion/Motion";
import { QRCode as QRCodeGenerator } from 'react-qrcode-logo';

const QrCode = () => {

	const [url, setUrl] = useState<string>();

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const token = queryParams.get('token');
		let decodedToken: string;

		if(!token) {
			decodedToken = 'https://nhs.limbic.ai/connect/care-app';
		} else {
			try {
				decodedToken = atob(token);
			} catch(e) {
				decodedToken = 'https://nhs.limbic.ai/connect/care-app';
			}
		}

		setUrl(() => decodedToken)
	}, [setUrl])

	return (
		<div className={ styles.container }>
			<div className={ styles.card }>
				<img className={ styles.limbicLogo } alt="Limbic logo" src={ Logo }/>
				{url && <Motion duration={.3} className={ styles.content }>
					<div className={ styles.qr }>
						<QRCodeGenerator value={url} ecLevel="Q" bgColor="white" logoImage={Limbic} removeQrCodeBehindLogo size={ 200 }/>
					</div>
					<div className={ styles.textContainer }>
						<h1>Continue your conversation in Limbic Care</h1>
						<div className={ styles.explanation }>
							<div><p>Please use your mobile device to scan the QR code, if you're on a desktop computer</p></div>
							<div className={ styles.downloadContainer }>
								<p>If you are on a mobile device, please click on the download links below</p>
								<div className={ styles.downloadLinks }>
									<a href={ url }><img alt="Get it in the App Store" src={ AppStore }/></a>
									<a href={ url }><img alt="Get it from Google Play" src={ GooglePlay }/></a>
								</div>
							</div>
						</div>
					</div>
				</Motion> }
				{!url && <Motion className={styles.error}>
					<h2>Uh oh! Something unexpected happened</h2>
					<p>Please contact us <a href={'mailto:admin@limbic.ai'}>here</a> to resolve this issue</p>
				</Motion>}
			</div>
		</div>
	)
}

export default QrCode;
